<template>
	<div>
		<div
			class="flex bg-white rounded-t-md transition-all duration-300 ease-in-out"
			:class="{' shadow-md shadow-primary-400' : active}"
		>
			<!-- dd -->
			<div class="relative grow pt-2 base-input rounded-t-md transition-all duration-300 ease-in-out">
				<label
					class="absolute pl-5 py-1 font-semibold text-[#035794] normal transition-all duration-300 ease-in-out hasPlaceholder"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
					}"
				>
					<span>{{ label ?? "Base Input" }}</span>
				</label>
				<textarea
					v-bind="$attrs"
					@input="valChange('input', $event);"
					@blur=" handleBlur($event);valChange('blur', $event);"
					@focus="valChange('focus', $event)"
					@change="handleChange($event);valChange('change', $event)"
					@keydown="valChange('keydown', $event)"
					@keyup="valChange('keyup', $event)"
					@keypress="valChange('keypress', $event)"
					:value="modelValue ?? value"
					:placeholder="customPlaceholder ?? label"
					:disabled="disabled"
					:readonly="readonly"
					class="input-element normal focus:outline-none rounded-t-md h-36"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
						hasPlaceholderInput: customPlaceholder,
					}"
				/>
				<label
					class="absolute pl-5 py-1 font-semibold text-[#035794] normal transition-all duration-300 ease-in-out"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
					}"
				>
					<span>{{ label ?? "Base Input" }}</span>
				</label>
				<span v-if="required" class="absolute top-0 right-1 text-red-600 font-bold">*</span>
			</div>
			<div v-if="this.$slots.append" class="flex w-12 h-12 border-l-2 self-center">
				<slot name="append" />
			</div>
		</div>
		<div
			class="text-xs font-semibold my-1 px-2 h-4 messages animate-bounce ease-out duration-300"
			v-show="!noDetails"
		>
			<slot name="messages">
				<span class="text-red-500" v-if="error ?? errorMessage">{{ error ?? errorMessage }}</span>
				<span class="text-red-500" v-if="warningMessage">{{ warningMessage }}</span>
				<span class="text-red-500" v-if="successMessage">{{ successMessage }}</span>
				<span v-else-if="hint" class="text-grey-300">{{ hint }}</span>
			</slot>
		</div>
	</div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef, watch } from "vue";
export default {
	name: "BaseInput",
	inheritAttrs: false,
	props: {
		modelValue: {},
		value: {},
		label: String,
		customPlaceholder: String,
		errorMessage: { type: String },
		warningMessage: { type: String },
		successMessage: { type: String },
		noDetails: Boolean,
		hint: String,
		refKey: { type: String, default: "" },
		rules: [String, Object, Array],
		immediate: { type: Boolean },
		required: { type: Boolean },
		active: { type: Boolean },
		disabled: { type: Boolean },
		readonly: { type: Boolean },
	},
	setup(props, { emit }) {
		const {
			value: inputValue,
			errorMessage: error,
			handleBlur,
			handleChange,
			resetField,
			meta,
		} = useField(toRef(props, "refKey"), props.rules ?? null, {
			initialValue: props.modelValue ?? props.value,
			validateOnMount: props.immediate,
			validateOnValueUpdate: props.immediate,
			standalone: !!props.rules,
		});

		resetField();
		watch(inputValue, (val) => emit("update:modelValue", val));

		// to fully support two-way binding you sync the vee-validate ref when the prop changes
		watch(
			() => props.modelValue,
			(v) => v !== inputValue.value && (inputValue.value = v)
		);

		return { inputValue, error, handleBlur, handleChange, meta };
	},
	emits: [
		"update:modelValue",
		"input",
		"blur",
		"focus",
		"change",
		"keydown",
		"keyup",
		"keypress",
	],
	methods: {
		valChange(eventName, event) {
			if (event.keyCode == 219 || event.keyCode == 221) {
				event.preventDefault()
				return
			}
			this.$emit(eventName, event?.target?.value);
			if (!this.$props.disabled && !this.$props.readonly) {
				this.$emit("update:modelValue", event?.target?.value);
			}
		},
	},
	watch: {},
};
</script>

<style lang="postcss" scoped >
.messages {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
label.normal {
	@apply transition duration-300 ease-in-out;
}
.base-input:focus-within > label.normal:not(.error, .warning, .success) {
	@apply text-[#0b7db6];
}
label.error {
	@apply text-red-500;
}
label.warning {
	@apply text-red-500;
}
label.success {
  @apply text-green-500;
}

label {
	top: 50%;
}
.input-element:not(:placeholder-shown) ~ label {
	@apply text-xs;
	top: 2px;
}

.input-element:not(:placeholder-shown) {
	@apply pb-1 pt-5;
}

.input-element:focus ~ label {
	@apply text-xs;
	top: 2px;
}

.input-element:focus::placeholder {
	opacity: 0;
}

.input-element:placeholder-shown:not(.input-element:focus) ~ label {
	@apply hidden;
}

.input-element {
	@apply block bg-white w-full pl-5 py-3 border-b-2 border-grey-200 font-normal text-black outline-0 transition duration-300 ease-in-out text-sm;
}
.input-element:disabled {
	@apply !text-gray-400;
}
.input-element.normal {
	@apply focus:border-[#0b7db6];
}
.input-element.error {
	@apply border-red-500;
}
.input-element.warning {
	@apply border-amber-500;
}
.input-element.success {
  @apply border-green-500;
}

.input-element:focus {
	@apply pb-1 pt-5 outline outline-2 outline-[#0b7db6];
}

::placeholder {
	@apply text-[#686663] font-semibold;
}

label.hasPlaceholder{
	@apply !text-xs pb-1;
	top: 2px !important;
}

.hasPlaceholderInput::placeholder{
	@apply text-darkprimary-100 italic absolute;
	top:11%;

}
</style>
