import axios from 'axios'
// import { useStore } from "vuex";
// import { computed } from "vue";

// const store = useStore();
// const user = computed(() => store.getters["auth/getUser"]);

const base = axios.create({

	// token: '',
	baseURL: 'https://api.assic.online/',
	headers: {
		'Content-Type': 'application/json'
		// , 'Authorization': `ASSIC ${user.value.token}`,
	},
})


export default base