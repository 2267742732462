<template>
  <div
    class="z-50 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
    id="exampleModalScrollable"
    tabindex="-1"
    aria-labelledby="exampleModalScrollableLabel"
    aria-hidden="true"
  >
    <div class="sm:h-[calc(50%-3rem)] w-6/12 my-6 mx-auto relative pointer-events-none">
      <div
        class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
      >
        <div
          class="flex flex-shrink-0 items-center justify-between p-5 border-b border-gray-200 rounded-t-md"
        >
          <!-- title -->
          <h5
            class="text-xl font-medium leading-normal text-gray-800"
            id="exampleModalScrollableLabel"
          >
            <slot name="header"></slot>
          </h5>
        </div>

        <!-- content -->
        <div class="flex-auto overflow-y-auto relative p-5">
          <slot name="content"></slot>
        </div>

        <!-- footer -->
        <div
          class="flex flex-shrink-0 flex-wrap items-center justify-between p-5 border-t text-sm border-gray-200 rounded-b-md"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
  <slot name="background"></slot>
</template>

<script>
export default {};
</script>

<style>
</style>
