<template>
	<Datepicker
		:value="modelValue"
		@update:modelValue="handleDate"
		v-bind="{...$attrs, ...$props, disabled: !!disabled}"
		@open="callOpenHandler($event)"
		:auto-position="true"
		teleport-center
    autoApply
	>
		<template #trigger>
			<div
				class="relative flex bg-grey-100 rounded-t-md"
				:class="{' shadow-md shadow-primary-400' : active}"
			>
				<div class="relative grow base-input rounded-t-md">
					<input
						:disabled="!!disabled"
						:readonly="readonly"
						v-bind="$attrs"
						:value="formatedDate"
						@blur="handleBlur"
						@change="handleChange"
						class="input-element normal focus:outline-none rounded-t-md"
						:placeholder="label"
						:class="{
							error: errorMessage,
							warning: warningMessage,
							success: successMessage,
							}"
					/>
					<label
						class="absolute pl-5 font-semibold text-[#035794] normal"
						for="userName"
						:class="{
								error: errorMessage,
								warning: warningMessage,
								success: successMessage,
							}"
					>
						<span>{{ label ?? "Base Input"}}</span>
					</label>
				</div>
				<span v-if="required" class="absolute top-0 right-1 text-red-600 font-bold">*</span>
				<div id="calender-icon" class="append-slot w-12 outline outline-2 outline-gray-300 flex justify-center items-center border-b-2">
					<CalendarIcon class="w-5 h-5 text-[#035794]" />
				</div>
			</div>
			<div
				class="text-xs font-semibold my-1 px-2 messages"
				v-if="!noDetails"
				:class="hint || error || errorMessage || warningMessage || successMessage || $slots.messages ? 'min-h-4' : 'h-4'"
			>
				<slot name="messages">
					<Transition name="fade" mode="out-in">
						<span class="text-red-500" v-if="error ?? errorMessage">{{ error ?? errorMessage }}</span>
						<span v-else-if="hint">{{ hint }}</span>
					</Transition>
				</slot>
			</div>
		</template>
	</Datepicker>
</template>

<script>
import format from "date-fns/format";
import Datepicker from "@vuepic/vue-datepicker";
import { map } from "lodash";
import { useField } from "vee-validate";
import { nextTick, toRef, watch } from "vue";
import { CalendarIcon } from '@heroicons/vue/24/solid';

export default {
	name: "BaseDatePicker",
	inheritAttrs: false,
	extends: Datepicker,
	props: {
		modelValue: {},
		value: {},
		label: String,
		errorMessage: { type: String },
		warningMessage: { type: String },
		successMessage: { type: String },
		noDetails: Boolean,
		hint: String,
		required: { type: Boolean },
		noEmpty: { type: Boolean },
		refKey: { type: String, default: "" },
		active: { type: Boolean },
		disabled: { type: Boolean },
		readonly: { type: Boolean },
	},
	emits: [
		"update:modelValue",
		"input",
		"blur",
		"focus",
		"change",
		"keydown",
		"keyup",
		"keypress",
	],
	setup(props, { emit }) {
		const {
			value: inputValue,
			errorMessage: error,
			handleBlur,
			handleChange,
			resetField,
			meta,
		} = useField(toRef(props, "refKey"), props.rules ?? null, {
			initialValue: props.modelValue ?? props.value,
			validateOnMount: props.immediate,
			validateOnValueUpdate: props.immediate,
			standalone: !!props.rules,
		});

		resetField();

		watch(inputValue, (val) => emit("update:modelValue", val));

		// to fully support two-way binding you sync the vee-validate ref when the prop changes
		watch(
			() => props.modelValue ?? props.value,
			(v) => v !== inputValue.value && (inputValue.value = v)
		);

		const callOpenHandler = async () => {
			await nextTick();
		};

		const altPositionHandler = () => {
			// await nextTick();
			let el = document.querySelector(".dp__menu");
			let x = el.offsetTop;
			let y = el.offsetHeight;
			let z = document.body.offsetHeight;

			if (x + y > z) {
				let xx = x + y - z;

				return {
					/* prettier-ignore */
					top: `calc(${el.style.top} - ${xx + 10}px) !important`,
					left: el.style.left,
					transform: el.style.transform,
				};
			}

			return {
				top: el.style.top,
				left: el.style.left,
				transform: el.style.transform,
			};
		};

		return {
			inputValue,
			error,
			handleBlur,
			handleChange,
			resetField,
			meta,
			callOpenHandler,
			altPositionHandler,
		};
	},

	created() {
		if (!this.modelValue && this.value)
			this.$emit("update:modelValue", this.value);
	},
	computed: {
		formatedDate() {
			if (!this.modelValue && !this.value && !this.noEmpty) return "";
			let x = format(
				new Date(
					this.$props.range
						? this.modelValue[0]
						: this.modelValue ?? this.value ?? new Date()
				),
				"yyyy-MM-dd"
			);
			let y;
			if (this.$props.range)
				y = format(
					new Date(
						this.modelValue?.[1] ?? this.modelValue ?? this.value
					),
					"yyyy-MM-dd"
				);
			return x + (y ? " to " + y : "");
		},
	},
	methods: {
		formatDate: format,
		handleDate(event) {
			let x = format(new Date(event?.[0] ?? event), "yyyy-MM-dd");
			if (this.$props.range)
				x = map(event, (e) => format(new Date(e), "yyyy-MM-dd"));
			if (!this.$props.readonly || !this.$props.disabled)
				this.$emit("update:modelValue", x);
		},
	},
	components: {
		Datepicker,
    CalendarIcon
	},
	watch: {
		formatedDate: {
			handler(v) {
				if (!this.$props.readonly || !this.$props.disabled)
					this.$emit("update:modelValue", v);
			},
			immediate: true,
		},
	},
};
</script>
<style lang="postcss" scoped >
.messages {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
label.normal {
	@apply transition duration-300 ease-in-out font-body;
}
.base-input:focus-within > label.normal:not(.error, .warning, .success) {
	@apply text-[#0b7db6];
}
label.error {
	@apply text-red-500;
}
label.warning {
	@apply text-red-500;
}
label.success {
  @apply text-green-600;
}

label {
	top: 50%;
}
.input-element:not(:placeholder-shown) ~ label {
	@apply text-xs;
	top: 2px;
}

.input-element:not(:placeholder-shown) {
	@apply pb-1 pt-5;
}

.input-element:focus ~ label {
	@apply text-xs;
	top: 2px;
}

.input-element:focus::placeholder {
	opacity: 0;
}

.input-element:placeholder-shown:not(.input-element:focus) ~ label {
	@apply hidden;
}

.input-element {
	@apply block font-body font-normal bg-grey-100 w-full pl-5 py-3 border-b-2 border-grey-200 text-gray-800 outline outline-2 outline-gray-300 transition-all duration-200 ease-in-out text-sm;
  background-color: #fff;
}
 .input-element:disabled {
	@apply !text-gray-400
}
.input-element.normal {
	@apply focus:border-[#0b7db6];
}
.input-element.error {
	@apply border-red-500;
}
.input-element.warning {
	@apply border-amber-500;
}
.input-element.success {
  @apply border-green-500;
}

.input-element:focus {
	@apply pb-1 pt-5 outline outline-2 outline-[#0b7db6];
}

::placeholder {
	@apply text-[#686663] font-semibold;
}

.rounded-t-md {
  border-radius: 0.375rem;
}

#calender-input{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0rem;
}

#calender-icon{
  border-top-left-radius: 0rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0.375rem;
}
</style>
