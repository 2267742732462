<template>
  <section class="relative block" style="height: 550px;">
    <div class="absolute top-0 w-full h-full bg-center bg-cover bg-primary-500">
      <!-- <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span> -->
    </div>
    <div
      class="border-none top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
      style="height: 70px;"
    >
      <svg
        class="absolute bottom-0 overflow-hidden border-none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon class="text-gray-100 fill-current" points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderShape",
};
</script>

<style>
</style>