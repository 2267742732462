<template>
  <div class="bg-white rounded p-5 shadow-md h-full w-full">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCard",
};
</script>

<style>
</style>