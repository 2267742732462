<template>
  <div class="navbar fixed bg-white z-20 shadow-md">
    <div class="w-4/5 mx-auto">
      <div class="flex-1">
        <router-link to="/">
          <img
            src="/images/assic-logo.png"
            class="btn bg-transparent border-none -mt-2"
            style="max-height: 50px !important;
            height: auto;
            margin: 0 80px 0 0;
            margin-left: 0 !important;
            padding: 0 !important;"
            />
        </router-link>
        <!-- <p>he</p> -->
      </div>

      <div class="flex-none text-primary-500 text-base font-normal">
        <ul class="menu menu-horizontal p-0">
          <li v-if="user">
            <span class="text-gray-400 text-sm block">Hello {{ user.first_name }}</span>
          </li>
          <li v-if="user">
            <router-link
              to="/dashboard"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Dashboard</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/Dashboard.svg" />
            </router-link>
          </li>
          <li v-if="user">
            <router-link
              to="/workout"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Workout</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/Workout.svg" />
            </router-link>
          </li>
          <li>
            <router-link
              to="/assic-calling"
              class="uppercase text-gray-600 hover:text-gray-800 text-sm flex"
            >
              <p>Assic Calling</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/Call.svg" />
            </router-link>
          </li>
          <li v-if="user">
            <a
              target="_blank" href="https://www.assic-health.com/about/"
              class="uppercase text-gray-600 hover:text-gray-800 text-sm flex"
            >
              <p>About</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/About.svg" />
          </a>
          </li>
          <li v-if="!user">
            <router-link
              to="/register"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Register</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/UserRegister.svg" />
            </router-link>
          </li>
          <li v-if="!user">
            <router-link
              to="/login"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Login</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/LogInOut.svg" />
            </router-link>
          </li>
          <li v-if="user">
            <router-link
              to="/profile"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Edit Profile</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/UserEdit.svg" />
            </router-link>
          </li>
          <li v-if="user">
            <router-link
              to="/login"
              @click="setUser(null)"
              class="uppercase text-gray-600 hover:text-primary-500 text-sm flex"
            >
              <p>Logout</p>
              <img class="h-4 w-4 -ml-1" src="../assets/img/LogInOut.svg" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NavComponent",
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    ...mapActions({
      setUser: "auth/setUser",
    }),
  },
};
</script>

<style lang="postcss">
.menu li {
  @apply hover:bg-grey-100 rounded-md;
}
</style>
