export default {
	namespaced: true,
	state: {
		user: null

	},
	getters: {
		getUser: state => state.user
	},
	mutations: {
		SET_USER(state, userPayload) {
			state.user = userPayload
		}
	},
	actions: {
		setUser({ commit }, userPayload) {
			commit('SET_USER', userPayload)
		}
	},
}