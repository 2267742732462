<template>
	<div>
		<div
			class="flex bg-grey-100 rounded-t-md transition-all duration-300 ease-in-out"
			:class="{' shadow-md shadow-primary-400' : active}"
		>
			<div v-if="this.$slots.prepend" class="flex justify-center items-center p-2">
				<slot name="prepend" />
			</div>
			<!-- dd -->
			<div class="relative grow base-input rounded-t-md transition-all duration-300 ease-in-out bg-white">
				<label
					v-if="customPlaceholder"
					class="absolute pl-5 font-semibold text-grey-300 normal transition-all duration-300 ease-in-out hasPlaceholder"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
					}"
				>
					<span>{{ label ?? "Base Input" }}</span>
				</label>
				<input
					v-bind="$attrs"
					@input="valChange('input', $event);"
					@blur=" handleBlur($event);valChange('blur', $event);"
					@focus="valChange('focus', $event)"
					@change="handleChange($event);valChange('change', $event)"
					@keydown="valChange('keydown', $event)"
					@keyup="valChange('keyup', $event)"
					@keypress="valChange('keypress', $event)"
					:value="modelValue ?? value"
					:placeholder="customPlaceholder ?? label"
					class="input-element normal focus:outline-none rounded-t-md pr-5"
					:disabled="!!disabled"
					:readonly="readonly"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
						hasPlaceholderInput: customPlaceholder,
						'text-right': align == 'right',
						'text-center': align == 'center',
						'text-left': align == 'left',
					}
					"
					:autocomplete="autocomplete"
				/>
				<label
					class="absolute pl-5 font-semibold text-[#035794] normal transition-all duration-300 ease-in-out"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessage,
						success: successMessage,
					}"
				>
					<span v-if="additionalLabel == false">{{ label ?? "Base Input" }}</span>
          <span v-else-if="additionalLabel == true">{{ label }} ({{ additionalLabelValue }})</span>
				</label>
				<span v-if="required" class="absolute top-0 right-1 text-red-600 font-bold">*</span>
			</div>
			<div v-if="this.$slots.append" class="flex min-w-[3rem] h-12 border-l-2 self-center">
				<slot name="append" />
			</div>
		</div>
		<div
			class="text-xs font-semibold my-1 px-2 messages"
			v-show="!noDetails || hint || error || errorMessage || warningMessage || successMessage"
			:class="hint || error || errorMessage || warningMessage || successMessage || $slots.messages ? 'min-h-4' : 'h-4'"
		>
			<Transition name="fade" mode="out-in">
				<span class="text-red-500" v-if="error ?? errorMessage">{{ error ?? errorMessage }}</span>
				<span class="text-red-500" v-else-if="warningMessage">{{ warningMessage }}</span>
				<span class="text-tertiary-500" v-else-if="successMessage">{{ successMessage }}</span>
				<span v-else-if="hint" class="text-grey-300">{{ hint }}</span>
			</Transition>
			<slot name="messages"></slot>
		</div>
	</div>
</template>

<script>
import { useField } from "vee-validate";
import { toRef, watch } from "vue";
export default {
	name: "BaseInput",
	inheritAttrs: false,
	props: {
		modelValue: {},
		value: {},
		label: String,
    additionalLabel: { type: Boolean, default: false },
    additionalLabelValue: { type: String, default: "" },
		customPlaceholder: String,
		errorMessage: { type: String },
		warningMessage: { type: String },
		successMessage: { type: String },
		noDetails: Boolean,
		hint: String,
		refKey: { type: String, default: "" },
		rules: [String, Object, Array],
		immediate: { type: Boolean },
		required: { type: Boolean },
		active: { type: Boolean },
		disabled: {},
		readonly: { type: Boolean },
		autocomplete: {},
		align: { type: String, default: "left" },
	},
	setup(props, { emit }) {
		const {
			value: inputValue,
			errorMessage: error,
			handleBlur,
			handleChange,
			resetField,
			meta,
		} = useField(toRef(props, "refKey"), props.rules ?? null, {
			initialValue: props.modelValue ?? props.value,
			validateOnMount: props.immediate,
			validateOnValueUpdate: props.immediate,
		});

		resetField();
		watch(inputValue, (val) => {
			if (!props.readonly || !props.disabled) {
				emit("update:modelValue", val);
			}
		});

		const valChange = (eventName, event) => {
			emit(eventName, event?.target?.value);
			!props.readonly && emit("update:modelValue", event?.target?.value);
		};

		// to fully support two-way binding you sync the vee-validate ref when the prop changes
		watch(
			() => props.modelValue ?? props.value,
			(v) => v !== inputValue.value && (inputValue.value = v)
		);

		return { inputValue, error, handleBlur, handleChange, meta, valChange };
	},
	emits: [
		"update:modelValue",
		"input",
		"blur",
		"focus",
		"change",
		"keydown",
		"keyup",
		"keypress",
	],
};
</script>
<style lang="postcss" scoped >
.messages {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
label.normal {
	@apply transition duration-300 ease-in-out;
}
.base-input:focus-within > label.normal:not(.error, .warning, .success) {
	@apply text-[#0b7db6];
}
label.error {
	@apply text-red-500;
}
label.warning {
	@apply text-red-500;
}
label.success {
  @apply text-green-500;
}

label {
	top: 50%;
}
.input-element:not(:placeholder-shown) ~ label {
	@apply text-xs;
	top: 2px;
}

.input-element:not(:placeholder-shown) {
	@apply pb-1 pt-5;
}

.input-element:focus ~ label {
	@apply text-xs;
	top: 2px;
  background-color: #fff;
}

.input-element:focus::placeholder {
	opacity: 0;
}

.input-element:placeholder-shown:not(.input-element:focus) ~ label {
	@apply hidden;
}

.input-element {
	@apply block bg-grey-100 w-full pl-5 py-3 border-b-2 border-grey-200 font-normal text-black outline outline-2 outline-gray-300 transition duration-300 ease-in-out text-sm;
  background-color: #fff;
}
.input-element:disabled {
	@apply !text-gray-400;
}
.input-element.normal {
	@apply focus:border-[#0b7db6];
}
.input-element.error {
	@apply border-red-500;
}
.input-element.warning {
	@apply border-amber-500;
}
.input-element.success {
  @apply border-green-500;
}

.input-element:focus {
	@apply pb-1 pt-5 outline outline-2 outline-[#0b7db6];
}

::placeholder {
	@apply text-[#686663] font-semibold text-left;
}

label.hasPlaceholder{
	@apply !text-xs;
	top: 2px !important;
}

.hasPlaceholderInput::placeholder{
	@apply text-darkprimary-100 italic absolute;
	top:48%;
}

.rounded-t-md {
  border-radius: 0.375rem;
}

</style>
