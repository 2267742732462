<template>
  <NavComponent></NavComponent>
  <HeaderShape></HeaderShape>

  <section class="relative pt-5 pb-12 bg-[#F8F8F8]">
    <div class="container mx-auto lg:px-5 xl:px-20">
      <!-- HEADER -->
      <div class="headerDiv mb-5">
        <base-card class="p-0">
          <img src="https://assic-files.ams3.digitaloceanspaces.com/app-icons/performance-fingerprint-app.png" alt class="rounded w-32 h-32 mx-auto" />
        </base-card>
        <base-card class="col-span-5 flex bg-white">
          <h1 class="headingOne self-center text-paragraph-500 font-normal">
            My
            <span class="font-bold">Performance Fingerprint</span> Profile
          </h1>
        </base-card>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 bg-white p-10 rounded mb-6 shadow-xl">
        <ProfileSectionCard
          v-for="profileSection in profileSections"
          :key="profileSection.title"
          :profileSection="profileSection"
        ></ProfileSectionCard>
      </div>
    </div>
  </section>

  <FooterComponent></FooterComponent>
</template>

<script>
import ProfileSectionCard from "../components/ProfileSectionCard.vue";
import BaseCard from "../assets/BaseCard.vue";
import HeaderShape from "../assets/HeaderShape.vue";
import FooterComponent from "../components/FooterComponent.vue";
import NavComponent from "../components/NavComponent.vue";

export default {
  name: "DashboardView",

  components: {
    ProfileSectionCard,
    BaseCard,
    HeaderShape,
    FooterComponent,
    NavComponent
  },

  data() {
    return {
      selectedTab: "profiles",

      profileSections: [
        {
          title: "Activity Profile",
          desc: "Activity profile allow daily input data on training time, match time, gymnasium time and step counter as well as custom user defined activity items.",
          btnText: "View Activity Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/activity.png",
          to: "/activityprofile",
        },
        {
          title: "Fitness Profile",
          desc: "Exercise fitness testing is a measure of the body’s adaptation to a given or increasing workload. Monitor your fitness.",
          btnText: "View Fitness Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/fitness.png",
          to: "/fitnessprofile",
        },
        {
          title: "Body Profile",
          desc: "Body allow the user to track their mass, (BMI) and skin fold thickness measurements together with their expected daily caloric requirements.",
          btnText: "View Body Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/body.png",
          to: "/bodyprofile",
        },
        {
          title: "Pain Score Profile",
          desc: "View and track your pain level before, during and after exercise.",
          btnText: "View Pain Score Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/research.png",
          to: "/painprofile",
        },
        {
          title: "Conditioning Tests Profile",
          desc: "Monitor speed, strength & endurance conditioning parameters.",
          btnText: "View Conditioning Tests Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/strength.png",
          to: "/conditioningprofile",
        },
        {
          title: "Medical Profile",
          desc: "View your medical history on a secure platform and add other medical information.",
          btnText: "View Medical Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/medical_history.png",
          to: "/medicalprofile",
        },
        {
          title: "Laboratory Tests Profile",
          desc: "Any investigation e.g. laboratory test can be entered and tracked.",
          btnText: "View Laboratory Tests Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/labtest.png",
          to: "/labprofile",
        },
        {
          title: "Vitals Profile",
          desc: "Add and view different Vital parameters to help measure and track and your health.",
          btnText: "View Vitals Profile",
          externalLink: "false",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/vitals.png",
          to: "/vitalsprofile",
        },
        {
          title: "Shop",
          desc: "The ASSIC Shop features products in the sports science industry, skincare industry, sports strapping, vitamins, and more. Buy our products today!",
          btnText: "View Shop",
          externalLink: "true",
          src: "https://assic-files.ams3.digitaloceanspaces.com/app-icons/shop.png",
          to: "https://www.assic-health.com/shop/",
        },
        {
          title: "Learning",
          desc: "ASSIC Education is an online university platform for medical students, doctors, and professors who want FREE access to human anatomy courses.",
          btnText: "View Learning",
          externalLink: "true",
          src: "https://assic-files.ams3.digitaloceanspaces.com/app-icons/learning.png",
          to: "https://www.assic-health.com/education/",
        },
        {
          title: "Health Professionals",
          desc: "ASSIC has partnered with many health professionals in South Africa. You can either find a professional, book a professional, or register your practice.",
          btnText: "View Health Professionals",
          externalLink: "true",
          src: "https://assic-files.ams3.digitaloceanspaces.com/app-icons/health-professionals.png",
          to: "https://www.assic-health.com/health-professionals/",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
