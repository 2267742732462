<template>
	<div
		v-show="modelValue"
		class="relative overflow-hidden h-1.5 mb-2 text-xs flex rounded-sm bg-primary-200 transition-all w-full"
	>
		<div
			:style="{width: `${!isNaN(value) ? value*1 : (indeterminate ? 50 : 0 )}%` }"
			class="absolute top-0 shadow-none h-full bg-pink-500"
			:class="{'-left-1/2 animate-loading-animation': indeterminate && isNaN(value)}"
		></div>
	</div>
</template>

<script>
export default {
	props: {
		modelValue: { type: Boolean, default: true },
		// heigh: {type:Number},
		indeterminate: { type: Boolean, default: true },
		value: [Number, String],
	},
};
</script>

<style>
</style>