import { createStore } from 'vuex'
import auth from './modules/auth'
import VuexPersister from "./persister";
import SecureLS from 'secure-ls'

import API from "../services/api"
import { find } from 'lodash';
const SecureLocalStorage = new SecureLS({ encodingType: 'aes' }) // AES encryption and data compression
/* Can also accept other options as below:
* new SecureLS({encodingType: '', isCompression: false})
* new SecureLS({isCompression: false})
* SecureLS({encodingType: 'rc4', isCompression: false})
* new SecureLS({encodingType: 'rc4', isCompression: false, encryptionSecret: 's3cr3tPa$$w0rd@123'})
* More details are found here (https://www.npmjs.com/package/secure-ls) */

// JavaScript
const authPersister = new VuexPersister({ // new VuexPersister<State> with TypeScript
  key: 'vx',
  statesToPersist: [
    'auth.user',
  ],
  storage: {
    getItem: (key) => SecureLocalStorage.get(key),
    setItem: (key, value) => SecureLocalStorage.set(key, value),
    removeItem: (key) => SecureLocalStorage.remove(key),
    length: SecureLocalStorage.getAllKeys().length,
    clear: () => SecureLocalStorage.clear(),
    key: (key) => key
  }
})

const store = createStore({
  state: {
    profiles: null,
    allActivity: [],

    routines: [],
    import_routines: [],

    counter: 0,
  },

  mutations: {
    increment(state) {
      state.counter = state.counter + 1;
    },

    increase(state, payload) {
      state.counter = state.counter + payload.value;
    },

    addSelectedRoutines(state, routines) {
      state.routines = routines;
    },

    SET_PROFILES(state, profiles) {
      state.profiles = profiles
    },
    SET_ALL_ACTIVITY(state, allActivity) {
      state.allActivity = allActivity
    }
  },

  actions: {
    increment(context) {
      setTimeout(function () {
        context.commit('increment');
      }, 2000);
    },
    increase(context, payload) {
      console.log("Context", context)
      context.commit('increase', payload)
    },

    addSelectedRoutines(context, routines) {
      context.commit('addSelectedRoutines', routines)
    },

    fetchProfiles({ commit }, token) {
      API.get("/v2/profiles/", { headers: { 'Authorization': 'ASSIC ' + token } })
        .then(res => {
          console.log(res.data);
          commit("SET_PROFILES", res.data.details.profiles)
        })
    },

    async fetchAllActivity({ commit }, token) {
      try {
        const data = await API.get("/activity/get-all-current-year/", { headers: { 'Authorization': 'ASSIC ' + token } })
        commit('SET_ALL_ACTIVITY', data.data)
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    },

  },
  getters: {
    finalCounter(state) {
      return state.counter;
    },
    normalizedCounter(_, getters) {
      const finalCounter = getters.finalCounter;
      if (finalCounter < 0) {
        return 0;
      }
      if (finalCounter > 100) {
        return 100;
      }
      return finalCounter;
    },

    selectedRoutines(state) {
      return state.routines;
    },

    fitnessProfile: state => find(state?.profiles, { name: 'Fitness' }),
    activityProfile: state => find(state?.profiles, { name: 'Activity' }),
    bodyProfile: state => find(state?.profiles, { name: 'Body' }),
    painProfile: state => find(state?.profiles, { name: 'Pain Score' }),
    conditioningProfile: state => find(state?.profiles, { name: 'Conditioning Tests' }),
    medicalProfile: state => find(state?.profiles, { name: 'Medical' }),
    laboratoryProfile: state => find(state?.profiles, { name: 'Laboratory Tests' }),
    vitalsProfile: state => find(state?.profiles, { name: 'Vitals' }),

    getAllActivity: (state) => state.allActivity

  },
  modules: {
    auth,
  }
  , plugins: [authPersister.persist]
})

export default store;
