<template>
  <!-- MODAL -->
  <div
    class="mx-10 pt-20 mt-20 h-4/5 overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-3xl">
      <!-- modal content-->
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
      >
        <!--modal header-->
        <div
          class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t"
        >
          <h3 class="text-2xl font-semibold text-gray-900 mx-5">
            <slot name="header"></slot>
          </h3>
        </div>
        <!--modal body-->
        <div class="mx-5 px-5 pb-5">
          <slot name="content"></slot>
        </div>
        <!-- modal footer-->
        <div class="modalFooter">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
  <slot name="background"></slot>
</template>

<script>
export default {};
</script>

<style>
</style>