<template>
  <main>
    <section class="relative block" style="height: 300px;">
      <div class="absolute top-0 w-full h-full bg-center bg-cover bg-primary-500">
      </div>
      <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
        style="height: 70px;">
        <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
          version="1.1" viewBox="0 0 2560 100" x="0" y="0">
          <polygon class="text-gray-300 fill-current" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </section>
    <section class="relative py-16 bg-gray-300">
      <div class="container mx-auto w-4/5 lg:w-3/5">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 p-10">
          <h3 class="text-3xl font-semibold leading-normal mb-2 text-gray-900 text-center">Terms & Conditions</h3>
          <div class="text-sm text-center leading-normal mt-0 mb-2 text-gray-500 font-bold">
            Please read through our terms and conditions policy.
            <br />Contact us for any questions you might have.
          </div>
          <br />
          <br />
          <div class="text-xs mx-auto mb-5">
            <p class="mb-3 leading-loose">
              These terms and conditions outline the rules and regulations for the use of ASSIC’s Website, located at <a
                href="https://www.assic-health.com/" target="_blank">https://www.assic-health.com/</a>.
            </p>
            <p class="mb-3 leading-loose">
              By accessing this website we assume you accept these terms and conditions. Do not continue to use ASSIC if
              you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <p class="mb-3 leading-loose">
              The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
              and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant
              to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our
              Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the
              offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the
              Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and subject to, prevailing law of
              Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore as referring to same.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Cookies</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              We employ the use of cookies. By accessing ASSIC, you agreed to use cookies in agreement with the ASSIC’s
              Privacy Policy.
            </p>
            <p class="mb-3 leading-loose">Most interactive websites use cookies to let us retrieve the user’s details for each visit.
              Cookies
              are used by our website to enable the functionality of certain areas to make it easier for people visiting
              our website. Some of our affiliate/advertising partners may also use cookies.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">License</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              Unless otherwise stated, ASSIC and/or its licensors own the intellectual property rights for all material
              on ASSIC. All intellectual property rights are reserved. You may access this from ASSIC for your own
              personal use subjected to restrictions set in these terms and conditions.
            </p>
            <p class="mb-2">You must not:</p>
            <ul class="list-disc ml-5 mb-5 leading-relaxed">
              <li>Republish material from ASSIC</li>
              <li>Sell, rent or sub-license material from ASSIC</li>
              <li>Reproduce, duplicate or copy material from ASSIC</li>
              <li>Redistribute content from ASSIC</li>
            </ul>

            <p class="mb-3 leading-loose">This Agreement shall begin on the date hereof.</p>
            <p class="mb-3 leading-loose">
              Parts of this website offer an opportunity for users to post and exchange opinions and information in
              certain areas of the website. ASSIC does not filter, edit, publish or review Comments prior to their
              presence on the website. Comments do not reflect the views and opinions of ASSIC,its agents and/or
              affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To
              the extent permitted by applicable laws, ASSIC shall not be liable for the Comments or for any liability,
              damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance
              of the Comments on this website.
            </p>
            <p class="mb-3 leading-loose">
              ASSIC reserves the right to monitor all Comments and to remove any Comments which can be considered
              inappropriate, offensive or causes breach of these Terms and Conditions.
            </p>
            <p class="mb-2">You warrant and represent that:</p>
            <ul class="list-disc ml-5 mb-5 leading-relaxed">
              <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to
                do so;</li>
              <li>The Comments do not invade any intellectual property right, including without limitation copyright,
                patent or trademark of any third party;</li>
              <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful
                material which is an invasion of privacy</li>
              <li>The Comments will not be used to solicit or promote business or custom or present commercial
                activities or unlawful activity.</li>
            </ul>

            <p class="mb-3 leading-loose">
              You hereby grant ASSIC a non-exclusive license to use, reproduce, edit and authorize others to use,
              reproduce and edit any of your Comments in any and all forms, formats or media.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Hyperlinking to our Content</h3>
          <div class="text-sm mb-5">
            <p class="mb-2">
              The following organizations may link to our Website without prior written approval:
            </p>

            <ul class="list-disc ml-5 mb-5 leading-relaxed">
              <li>Republish material from ASSIC</li>
              <li>Sell, rent or sub-license material from ASSIC</li>
              <li>Reproduce, duplicate or copy material from ASSIC</li>
              <li>Redistribute content from ASSIC</li>
            </ul>

            <p class="mb-3 leading-loose">
              We will approve link requests from these organizations if we decide that: (a) the link would not make us
              look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any
              negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the
              absence of ASSIC; and (d) the link is in the context of general resource information.
            </p>
            <p class="mb-3 leading-loose">
              These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b)
              does not falsely imply sponsorship, endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking party’s site.
            </p>
            <p class="mb-3 leading-loose">
              If you are one of the organizations listed in paragraph 2 above and are interested in linking to our
              website, you must inform us by sending an e-mail to ASSIC. Please include your name, your organization
              name, contact information as well as the URL of your site, a list of any URLs from which you intend to
              link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks
              for a response.
            </p>

            <p class="mb-2">Approved organizations may hyperlink to our Website as follows:</p>
            <ul class="list-disc ml-5 mb-5 leading-relaxed">
              <li>By use of our corporate name; or</li>
              <li>By use of the uniform resource locator being linked to; or</li>
              <li>By use of any other description of our Website being linked to that makes sense within the context
                and format of content on the linking party’s site.</li>
            </ul>

            <p class="mb-3 leading-loose">
              No use of ASSIC’s logo or other artwork will be allowed for linking absent a trademark license
              agreement.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">iFrames</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              Without prior approval and written permission, you may not create frames around our Webpages that alter
              in any way the visual presentation or appearance of our Website.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Content Liability</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              We shall not be hold responsible for any content that appears on your Website. You agree to protect and
              defend us against all claims that is rising on your Website. No link(s) should appear on any Website that
              may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates
              the infringement or other violation of, any third party rights.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Your Privacy</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              Please read Privacy Policy.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Reservation of Rights</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              We reserve the right to request that you remove all links or any particular link to our Website. You
              approve to immediately remove all links to our Website upon request. We also reserve the right to amen
              these terms and conditions and it’s linking policy at any time. By continuously linking to our Website,
              you agree to be bound to and follow these linking terms and conditions.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Reservation of Rights</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              We reserve the right to request that you remove all links or any particular link to our Website. You
              approve to immediately remove all links to our Website upon request. We also reserve the right to amen
              these terms and conditions and it’s linking policy at any time. By continuously linking to our Website,
              you agree to be bound to and follow these linking terms and conditions.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Removal of links from our website</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              If you find any link on our Website that is offensive for any reason, you are free to contact and inform
              us any moment. We will consider requests to remove links but we are not obligated to or so or to respond
              to you directly.
              <br> <br>
              We do not ensure that the information on this website is correct, we do not warrant its completeness or
              accuracy; nor do we promise to ensure that the website remains available or that the material on the
              website is kept up to date.
            </p>
          </div>

          <h3 class="text-xl font-bold leading-normal mb-2 text-gray-900">Disclaimer</h3>
          <div class="text-sm mb-5">
            <p class="mb-3 leading-loose">
              If you find any link on our Website that is offensive for any reason, you are free to contact and inform
              us any moment. We will consider requests to remove links but we are not obligated to or so or to respond
              to you directly.
            </p>
            <p class="mb-3 leading-loose">
              We do not ensure that the information on this website is correct, we do not warrant its completeness or
              accuracy; nor do we promise to ensure that the website remains available or that the material on the
              website is kept up to date.
            </p>
            <p class="mb-2">
              To the maximum extent permitted by applicable law, we exclude all representations, warranties and
              conditions relating to our website and the use of this website. Nothing in this disclaimer will:
            </p>
            <ul class="list-disc ml-5 mb-5 leading-relaxed">
              <li>limit or exclude our or your liability for death or personal injury;</li>
              <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
              <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
              <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>

            <p class="mb-3 leading-loose">
              The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a)
              are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,
              including liabilities arising in contract, in tort and for breach of statutory duty.
            </p>
            <p class="mb-3 leading-loose">
              As long as the website and the information and services on the website are provided free of charge, we
              will not be liable for any loss or damage of any nature.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
